import classNames from 'classnames';

import sliderClasses from 'styles/exports/slider.module.scss';
import Typography from '@components/atoms/Typography';

import classes from './SliderDots.module.scss';

type Props = {
  items: Array<{
    id: number;
    companyName: string;
  }>;
  currentSlide: number;
  onClick: (itemIndex: number) => void;
};

const SliderDots = ({ items, currentSlide, onClick }: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <div className={classNames(classes.clientsWrapper, sliderClasses.dots)}>
      {items.map(({ id, companyName }, i) => (
        <Typography
          key={id}
          variant="body2"
          data-title={companyName}
          className={classNames(classes.client, {
            [classes.activeClient]: currentSlide === i,
          })}
          onClick={() => onClick(i)}
        >
          {companyName}
        </Typography>
      ))}
    </div>
  </div>
);

export default SliderDots;
