import { useState } from 'react';

const useTimeout = (ms = 1000) => {
  const [timeout, setTimeoutState] = useState<NodeJS.Timeout>();

  const start = (callback: () => void) => {
    setTimeoutState((currentTimeout) => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }

      return setTimeout(callback, ms);
    });
  };

  const clear = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  };

  return { start, clear };
};

export default useTimeout;
