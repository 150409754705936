import { useEffect } from 'react';

import useBoolean from 'hooks/useBoolean';
import useTimeout from 'hooks/useTimeout';

type Props = {
  controlledIsOpen?: boolean;
  withoutControlledClose?: boolean;
  timeout?: number;
  callback?: () => void;
};

type UseDrawerReturn = {
  isOpen: boolean;
  isFadingOut: boolean;
  open: () => void;
  close: () => void;
};

const useDrawer = ({
  controlledIsOpen,
  withoutControlledClose,
  timeout = 200,
  callback,
}: Props = {}): UseDrawerReturn => {
  const [isOpen, { setValue: setIsOpen }] = useBoolean(false);
  const [isFadingOut, { setValue: setIsFadingOut }] = useBoolean(false);
  const { start, clear } = useTimeout(timeout);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsFadingOut(true);
    start(() => {
      setIsOpen(false);
      if (typeof callback === 'function') {
        callback();
      }
      setIsFadingOut(false);
    });
  };

  useEffect(() => {
    if (typeof controlledIsOpen === 'boolean') {
      if (controlledIsOpen) {
        open();
      } else if (!withoutControlledClose) {
        close();
      }
    }
  }, [controlledIsOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => clear, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { isOpen, isFadingOut, open, close };
};

export default useDrawer;
