import { useEffect, useRef, MouseEventHandler, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import config from 'config';
import { Classes } from 'types/Classes';
import { appContainerSelector } from 'consts/app';
import CrossIcon from 'assets/icons/cross.svg';
import noop from 'utils/noop';
import Message from 'types/Message';
import useDrawer from 'hooks/useDrawer';

import classes from './Modal.module.scss';

enum SIZE {
  small = 'small',
  big = 'big',
  fullscreen = 'fullscreen',
}

const mapSizeToBoxClass: Record<SIZE, string> = {
  [SIZE.small]: 'boxSmall',
  [SIZE.big]: 'boxBig',
  [SIZE.fullscreen]: 'boxFullscreen',
};

const mapSizeToContentClass: Record<SIZE, string> = {
  [SIZE.small]: 'contentSmall',
  [SIZE.big]: 'contentBig',
  [SIZE.fullscreen]: 'contentFullscreen',
};

export type Props = {
  trigger?: ReactNode;
  children?: ReactNode | ((args: { close: () => void }) => ReactNode);
  title?: Message;
  size?: SIZE;
  classes?: Classes<'wrapper' | 'box'>;
  hideWhenClickOutside?: boolean;
  smallClose?: boolean;
  transparent?: boolean;
  controlledIsOpen?: boolean;
  withoutHeader?: boolean;
  withoutPadding?: boolean;
  withMobileClose?: boolean;
  withoutScaleAnimation?: boolean;
  withoutControlledClose?: boolean;
  onOpenChange?: (value: boolean) => void;
};

const Modal = ({
  trigger = '',
  children = '',
  title = '',
  classes: customClasses,
  size = SIZE.small,
  hideWhenClickOutside = false,
  smallClose = false,
  transparent = false,
  withoutHeader = false,
  withoutPadding = false,
  withMobileClose = false,
  withoutScaleAnimation = false,
  withoutControlledClose = false,
  controlledIsOpen = undefined,
  onOpenChange = noop,
}: Props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isFadingOut, isOpen, open, close } = useDrawer({
    controlledIsOpen,
    withoutControlledClose,
    timeout: config.defaultModalAnimationTime,
    callback: () => onOpenChange(false),
  });

  const onClose = () => close();

  const onOpen = () => {
    open();
    onOpenChange(true);
  };

  useEffect(() => {
    let blockBodyScroll = false;
    if (isOpen && document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
      blockBodyScroll = true;
    }

    return () => {
      if (blockBodyScroll) {
        document.body.style.overflow = 'visible';
      }
    };
  }, [isOpen]);

  const handleOutsideClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target !== wrapperRef.current) {
      return;
    }
    onClose();
  };

  return (
    <>
      {!!trigger && <div onClick={onOpen}>{trigger}</div>}
      {isOpen &&
        createPortal(
          <div
            className={classNames(classes.wrapper, customClasses?.wrapper, { [classes.fadingOut]: isFadingOut })}
            ref={wrapperRef}
            onClick={hideWhenClickOutside ? handleOutsideClick : undefined}
          >
            <div
              className={classNames(classes.box, customClasses?.box, classes[mapSizeToBoxClass[size]], {
                [classes.transparent]: transparent,
                [classes.withScale]: !withoutScaleAnimation,
              })}
            >
              {!withoutHeader && (
                <div
                  className={classNames(classes.header, {
                    [classes.withMobileClose]: size === SIZE.fullscreen || withMobileClose,
                  })}
                >
                  <div
                    className={classNames(classes.title, {
                      [classes.isSmallContent]: size === SIZE.small,
                      [classes.hasTitle]: !!title,
                    })}
                  >
                    {title}
                  </div>

                  <div
                    className={classNames(classes.close, {
                      [classes.smallClose]: smallClose,
                    })}
                    data-eid="close-modal"
                    onClick={onClose}
                  >
                    <CrossIcon className={classes.closeIcon} />
                  </div>
                </div>
              )}

              <div
                className={classNames(classes.content, classes[mapSizeToContentClass[size]], {
                  [classes.withoutPadding]: withoutPadding,
                })}
              >
                {typeof children === 'function' ? children({ close: onClose }) : children}
              </div>
            </div>
          </div>,
          document.querySelector(appContainerSelector) as Element
        )}
    </>
  );
};

Modal.size = SIZE;

export default Modal;
