import Image from '@next/image';
import classNames from 'classnames';

import { Testimonial as TestimonialType } from 'types/models/Testimonial';
import Typography from '@components/atoms/Typography';
import QuotationIcon from 'assets/icons/quotation.svg';

import classes from './Testimonial.module.scss';

type Props = Pick<
  TestimonialType,
  'personName' | 'companyName' | 'position' | 'text' | 'image' | 'companyImageFile'
> & {
  variant: 'default' | 'box';
};

const Testimonial = ({
  companyName,
  personName,
  text,
  image,
  variant,
  position,
  companyImageFile,
}: Props): JSX.Element => {
  const isBox = variant === 'box';

  return (
    <div className={classNames(classes.wrapper, { [classes.box]: isBox })}>
      {isBox && <QuotationIcon className={classes.quotationIcon} />}
      <div className={classes.imageWrapper}>
        <Image
          layout="fill"
          className={classes.image}
          alt={`${companyName} - ${personName}`}
          resolveConfig={{
            preset: 'testimonial',
            productName: companyName,
            extension: image.extension,
            name: image.basename,
            description: personName,
          }}
          {...(isBox ? { width: 100, height: 100 } : { width: 138, height: 138 })}
          hasBlendMode={false}
        />
        {companyImageFile && (
          <Image
            alt={companyName}
            resolveConfig={{
              preset: 'i',
              productName: companyName,
              extension: companyImageFile.extension,
              name: companyImageFile.basename,
              width: companyImageFile.width,
              height: companyImageFile.height,
            }}
            hasBlendMode={false}
          />
        )}
      </div>
      <div className={classes.description}>
        <Typography className={classes.descriptionText} variant="h2" weight="400" renderAs="span">
          {text}
        </Typography>
      </div>
      <div className={classes.author}>
        <Typography variant="h3" display="block" renderAs="div" className={classes.subtitle}>
          {isBox ? personName : companyName}
        </Typography>
        <Typography variant="body2" display="block">
          {isBox ? position : personName}
        </Typography>
      </div>
    </div>
  );
};

export default Testimonial;
