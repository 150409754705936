import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import sliderClasses from 'styles/exports/slider.module.scss';
import ArrowThickLeftIcon from '@icons/ArrowThickLeft';
import ArrowThickRightIcon from '@icons/ArrowThickRight';
import noop from 'utils/noop';

import classes from './Arrow.module.scss';

type Props = {
  disabled?: boolean;
  rawIcon?: boolean;
  variant?: 'left' | 'right';
  width?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const Arrow = ({
  disabled = false,
  rawIcon = false,
  variant = 'left',
  width = 14,
  onClick = noop,
}: Props): JSX.Element => {
  const IconComponent = variant === 'left' ? ArrowThickLeftIcon : ArrowThickRightIcon;
  const arrowClasses = !rawIcon
    ? [classes.arrow, variant === 'left' ? classes.arrowLeft : classes.arrowRight]
    : [classes.rawArrow];

  return (
    <div
      className={classNames(arrowClasses, sliderClasses.arrow, {
        [sliderClasses.arrowLeft]: variant === 'left',
        [sliderClasses.arrowRight]: variant === 'right',
        [sliderClasses.arrowDisabled]: disabled,
        [classes.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <IconComponent width={width} disabled={disabled} />
    </div>
  );
};

export default Arrow;
